<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "TepGraficaKmExtra",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          backGroundColor: "#ff1c79",
          type: "bar",
        },

        xAxis: {
          categories: [],
          title: {
            text: "Tarifas",
          },
        },

        yAxis: {
          title: {
            text: "Cantidad Kms Extra",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "KILOMETROS EXTRA * TARIFA",
          align: "center",
        },

        series: [
          {
            data: [{}],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/tep/dashboardTepNacionales/kmExtra", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaKmExtra(me.respuesta);
        });
    },

    async graficaKmExtra(resp) {
      let kmExtra = [];
      let tarifa = [];

      this.chartOptions.series[0].data = [{}];

      for (let i = 0; i < resp.length; i++) {
        if (resp[i].nTarifa != 0 && resp[i].nTarifa != null) {
          await tarifa.push(resp[i].nTarifa);
          await kmExtra.push(parseFloat(resp[i].sumaKmAdd));
        }
      }

      this.chartOptions.xAxis.categories = tarifa;

      for (let i = 0; i < tarifa.length; i++) {
        let objGraf = {
          y: kmExtra[i],
        };

        this.chartOptions.series[0].data[i] = objGraf;
        this.chartOptions.series[0].name = "N° Kms Extra";
      }
    },
  },
};
</script>
